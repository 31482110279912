<script setup>
import coverImage from '@/Components/Assets/cover.jpg'
import ApplicationLogo from '@/Components/ApplicationLogo.vue'

const year = new Date().getFullYear()
</script>

<template>
    <div class="flex h-screen">
        <div class="grid flex-1 lg:grid-cols-2">
            <div class="flex h-full flex-col justify-between py-12 px-6">
                <ApplicationLogo class="ml-6" />
                <slot name="main-content" />
                <div class="flex justify-end">
                    <p class="text-xs text-gray-400">© Veebisepad OÜ {{ year }}</p>
                </div>
            </div>
            <div class="h-full bg-transparent hidden lg:flex">
                <slot name="secondary-content">
                    <div class="relative drop-shadow-lg overflow-hidden">
                        <img
                            class="max-h-screen object-cover aspect-[4/3] w-full h-full transition-transform duration-300 ease-in-out hover:scale-105"
                            :src="coverImage"
                            alt=""
                        />
                    </div>
                </slot>
            </div>
        </div>
    </div>
</template>
